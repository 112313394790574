<template>
    <div id="FAQ">
        <section>
            <div class="faq-list">
                <div class="mt-10 mb-5">
                    <h1 class="center">Frequently Asked Questions</h1>
                </div>
                <div class="faq-item glass mt-2" v-for="(faq, index) in frequently_asked_questions" :key="index">
                    <h3 class="mb-1">{{ faq.question }}</h3>
                    <p>
                        {{ faq.answer }}
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { frequently_asked_questions } from "@/data/faqs.js";
export default {
    data() {
        return {
            frequently_asked_questions,
        };
    },
    methods: {},
};
</script>

<style scoped>
.faq {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 40px;
    background-color: white;
    align-items: center;
}

.faq h1 {
    font-style: normal;
    font-size: 30px;
    text-align: center;
    width: 100%;
}

.faq p {
    color: rgb(216, 216, 216);
    font-size: 15px;
    line-height: 1.6;
    width: 70%;
}

@media (max-width: 768px) {
    .faq {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        row-gap: 30px;
    }

    .faq p {
        width: 100%;
    }
}

.faq-list {
    height: auto;
    margin-top: 3em;
    margin-bottom: 3em;
    width: 100%;
}

.faq-list h1 {

    font-size: 30px;
}

.faq-list .faq-item {
    display: grid;
    padding: 30px;
    background-color: white;
    color: black;
    border-radius: 3px !important;
    align-items: center;
}

.faq-list .faq-item p {
    line-height: 1.8;
    color: black;
    font-size: 15px;
}
</style>
