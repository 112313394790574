<template>
  <!-- Foot -->
  <footer>
    <section class="footer-grid">
      <div class="footer-nav footer-nav__one">
        <div>
          <p class="footer-about">
            We are an organization focused at educating students by offering and
            teaching STEM and language based subjects the best way possible to
            help students evolve and excel in their knowledge of STEM and
            languages
          </p>
          <div class="footer-social">
            <img src="@/assets/icons/email.svg" alt="" />
            <span>
              <a href="mailto:info@msciclinic.com">info@msciclinic.com</a>
            </span>
          </div>
          <div class="footer-social telephone">
            <img src="@/assets/icons/telephone.svg" alt="" />
            <div>
              <span>+2348023003220</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-nav footer-nav__two">
        <div>
          <span class="footer-header">Company</span>
          <div class="footer-links">
            <ul>
              <li><a href="/team">Our Team</a></li>
              <li>Terms of Service</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-nav footer-nav__three">
        <div>
          <span class="footer-header">Contact</span>
          <div class="footer-links">
            <ul>
              <li> <a href="mailto:info@msciclinic.com">Contact us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      host: "https://MSciClinic.org",
    };
  },
};
</script>

<style scoped>
/* The footer section */
footer {
  padding: 2em 0px;
  margin-top: 5em;
  background-color: #ffffff;
  box-shadow: 2px 0px 4px rgba(60, 18, 0, 0.15);
  
  
}

.footer-nav span {
  display: block;
}

img.footer-logo {
  width: 97px;
  margin-bottom: 20px;
}

.footer-nav__one > div {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.footer-about,
.footer-social {
  font-size: 12px;
}

.footer-social {
  display: flex;
}

.footer-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer-social img {
  margin-right: 20px;
  width: 20px;
}

.footer-divider {
  height: 1px;
  width: 100%;
  background: rgba(60, 18, 0, 1);
}

.telephone {
  align-items: flex-start;
}

.telephone > div {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer-grid {
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 70px;
}

.footer-nav {
  flex-basis: 250px;
  padding: 20px 0;
  width: 100%;
  color: hsl(15, 7%, 11%);
}

.footer-links {
  font-size: 14px;
  font-weight: 500;
}

.subscribe-wrapper {
  display: flex;
  border-radius: 4px;
  backdrop-filter: blur(22px);
  background: #f5f5f5;
}

.footer-nav__four p {
  font-size: 14px;
  margin-bottom: 20px;
}

.subscribe-wrapper input {
  width: 80%;
  font-size: 12px;
  border: none;
  border-radius: 4px 0 0 4px;
  padding: 8px 12px;
  background: #f5f5f5;
}

.subscribe-wrapper button {
  width: 20%;
  border: none;
  border-radius: 0 4px 4px 0;
  background: #ff4c00;
  padding: 10px;
  cursor: pointer;
}

.subscribe-wrapper button img {
  width: 24px;
}

.footer-nav ul {
  
}

.footer-links ul li {
  margin-bottom: 12px;
  list-style: none;
}

@media (max-width: 800px) {
  footer {
    margin-top: 40px;
    padding-bottom: 60px;
  }
}

/* .footer-nav ul li span {
  color: rgb(63, 63, 63);
  font-size: 9px;
  padding: 3px;
} */

@media only screen and (max-width: 480px) {
  .footer-grid {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .footer-nav {
    padding: 5px 0;
  }

  .footer-header {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 840px) {
  .footer-grid {
    column-gap: 1rem;
  }
}

/* The footer section End */
</style>
