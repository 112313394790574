<template>
  <section>
    <div id="about" class="banner">
      <div class="banner-img">
        <img src="@/assets/images/home/hero-image2.png" alt="" />
      </div>
      <div class="banner1-text">
        <h3>Welcome to MSciClinic</h3>
        <p class="txt-b1">
          In the past 20-30 years, performance and interest of the younger
          generation in science, technology and mathematics (STEM) has been on a
          steady and worrisome decline and this is driven by the dearth in the
          quality of tutors/experts in these fields and little or no motivation
          from stakeholders in the education sector to reward excellence.
        </p>
        <p class="txt-b1">
          The gap was further widened with the COVID-19 pandemic in 2020 as a
          very little percentage of educational institutions globally was able
          to leverage technology for e-learning. It is estimated that over 1
          million school students are not getting adequate training in STEM.
        </p>
        <p class="txt-b1">
          We are an organization focused at educating students by offering and
          teaching STEM and language based subjects the best way possible to
          help students evolve and excel in their knowledge of science and
          languages
        </p>
        <span class="read-more-container">
          <p class="readmore">Read more</p>
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      homeImages,
    };
  },
};
</script>
<style scoped>
.banner {
  display: grid;
  grid-template-areas: "image text";
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 100px;
  width: 100%;
  align-items: center;
  margin: 100px auto 0 auto;
}
.banner .banner-img {
  width: 100%;
  object-fit: cover;
  grid-area: image;
}

@media (max-width: 768px) {
  .banner .banner-img {
    width: 100%;
    margin: 0 auto;
  }
}

.banner .banner1-text {
  grid-area: text;
}
h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}

h3 {
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 2s infinite linear;
  -webkit-animation: hue 10s infinite alternate-reverse;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.banner .banner1-text p {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 35px;
}
.banner .banner1-text .read-more-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  width: 50%;
}
.banner .banner1-text .read-more-container .readmore {
  font-size: 16px;
  color: var(--primary);
  cursor: pointer;
}
.banner .banner1-text .read-more-container img {
  width: 20%;
  cursor: pointer;
  margin-top: 5%;
}
@media screen and (max-width: 768px) {
  .banner {
    grid-template-areas:
      "text"
      "image";
    gap: 15px;
    padding: 10px;
    margin: 30px auto 37px auto;
  }
  .banner .banner-img {
    width: 80%;
  }
  .banner .banner1-text .read-more-container {
    width: 180px;
  }
  .banner .banner1-text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 19px;
  }
  .banner .banner1-text .read-more-container .readmore {
    font-size: 16px;
  }
  .banner .banner1-text .read-more-container img {
    width: 30%;
    margin-top: 6%;
  }
}
</style>
