<template>
  <section>
    <div class="mt-5">
    <h3 class="title-h1">How It Works?</h3>
    <div class="flow">
      <div class="d-card shadow-1">
        <img :src="`${homeImages.search}`" alt="" />
        <div class="card-item">
          <h5>Find Your Subject</h5>
          <p>
            Check through our platform and choose the course of your choice
            and get started.
          </p>
        </div>
      </div>
      <img class="direction" :src="`${homeImages.arrowFill}`" alt="" />
      <a href="https://docs.google.com/forms/d/e/1FAIpQLScpBB0OY94S3MhZnJWSOIFsQqQ089hdM9LUoVx0YQ9VxXGbkg/viewform?pli=1&pli=1" target="_blank">
      <div class="d-card shadow-1">
        <img :src="`${homeImages.calendar}`" alt="" />
        <div class="card-item">
          <h5>Schedule a Trial class</h5>
          <p>
            When you’ve found the course, book a session to meet with your
            instructor and learn more about the course.
          </p>
        </div>
      </div>
      </a>
      <img class="direction" :src="`${homeImages.arrowFill}`" alt="" />
      <div class="d-card shadow-1">
        <img :src="`${homeImages.badge}`" alt="" />
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSerDMreWBW7mFtevKtlamqGHJ51LdMx8iZUvLohdgtzU4pBJg/viewform" target="_blank">
        <div class="card-item">
          <h5>Start Learning</h5>
          <p>
            After completing the application process, you will be matched with a teacher to teach and guide you on the selected subject.
          </p>
        </div>
        </a>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data:()=>{
    return{
      homeImages,
    }
  },
}
</script>
<style scoped>
  .title-h1 {
    margin-top: 100px;
    width: 200px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0 auto;
    line-height: 2em;
  }

  
  .flow{
    display: flex;
    gap:30px;
    place-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .flow .direction{
    width:40px;
  }
  
  .flow .d-card{
    width:300px;
    height: 250px;
    padding: 30px 20px;
  }
  
  .flow .d-card img{
    width:40px;
  }
  
  .flow .d-card .card-item h5{
    margin:30px 0 15px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  
  .flow .d-card .card-item p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (max-width:768px){
    .flow{
      flex-direction: column;
      gap:10px;
    }
    
    .flow .direction{
      width: 40px;
      transform:  rotate(90deg);
      margin:15px 0;
    }
    
    .title-h1{
      text-decoration:none;
    }
  }
</style>