// layouts
import main from '@/layout/main.vue';
import empty from '@/layout/empty.vue';

// pages
import Home from '@/pages/home.vue';
import Team from '@/pages/team.vue';

// errors
import error404 from '@/pages/errors/error404.vue';

const routes = [
  {
    path: '/',
    component: main,
    props: true,
    children: [
      // home page
      { path: '', component: Home, meta: { entryPoint: true } },
      { path: 'home', component: Home },

      // team page
      { name: 'team', path: 'team', component: Team },
    ],
  },

  {
    path: '/',
    props: true,
    component: empty,
    children: [
      { name: 'error404', path: 'error404', component: error404 },
    ],
  },

  {
    path: '/:catchAll(.*)*',
    component: error404,
  },
];

export default routes;
