<template>
  <section id="pricing" class="pricing-plan">
    <h3>Pricing Plans</h3>
    <div class="pricing-plan-wrapper">
      <div
        v-for="(plan, index) in pricingPlans"
        :key="index"
        class="pricing-plan-box"
        :class="{ 'pricing-plan-border': index === 1 }"
      >
        <div>
          <div>
            <div class="plan-name">{{ plan.name }}</div>
            <div class="fee">
              <span class="plan-fee">{{ plan.fee }}</span
              ><span class="per-month">/ mon</span>
            </div>
            <div class="plan-billing">billed monthly</div>
            <div class="add-on">
            </div>
            <div class="plan-benefits">
              <ul v-for="(benefit, index) in plan.benefits" :key="index">
                <li class="plan-benefit">
                  <!-- <img
                    class="benefit-icon"
                    src="@/assets/icons/plan-benefit.svg"
                    alt="benefit icon"
                  /> -->
                  {{ benefit }}
                </li>
              </ul>
            </div>
          </div>
          <div><button class="plan-button">Choose Plan</button></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      pricingPlans: [
        {
          name: "Private plan",
          fee: "$30",
          addon: null,
          benefits: [
            "Group class of 5",
            "Tutoring (2ce weekly)",
            "Monthly assessment",
            "Weekly assessment",
            "",
            "",
          ],
        },
        {
          name: "1 on 1 plan - STEM",
          fee: "$80",
          benefits: [
            "1 on 1 tutoring",
            "Tutoring (2ce weekly)",
            "Weekly assessment",
            "Monthly assessment",
            "School Homework support",
          ],
        },
        {
          name: "1 on 1 plan - Coding/Languages",
          fee: "$100",
          benefits: [
            "1 on 1 tutoring",
            "Tutoring (2ce weekly)",
            "Weekly assessment",
            "Monthly assessment/Project",
            "",
            "",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

.pricing-plan {
  padding: 60px 0;
  text-align: center;
}

.pricing-plan-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  max-width: 1030px;
  margin: 55px auto 0 auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

.divider {
  background: #e07826;
  height: 8px;
  width: 125px;
  border-radius: 14px;
  display: inline-block;
}

.pricing-plan-box {
  padding: 50px 15px 25px 20px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0px 4px 30px 0px rgba(196, 196, 196, 0.35);
  background: white;
  border: 0.1px solid white;
  flex-basis: 300px;
  height: 100%;
}

.pricing-plan-box:hover {
  border: 0.1px solid #e07826;
}

.plan-name {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 35px;
}

.fee {
  margin-bottom: 15px;
}

.plan-fee {
  font-weight: bold;
  font-size: 32px;
}

.per-month {
  font-size: 24px;
}

.plan-billing {
  margin-bottom: 35px;
}

.add-on {
  margin-bottom: 12px;
}

.plan-benefits {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 45px;
}

.plan-benefit {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.plan-benefit img {
  width: 20px;
}

.plan-button {
  background: #e07826;
  border-radius: 4px;
  padding: 15px 70px;
  width: 95%;
  border: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .divider {
    display: none;
  }

  .pricing-plan-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}
</style>
