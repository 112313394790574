<template>
  <div class="teams">
    <div class="teams-label">Our Team</div>
    <p>
      Our expert team is made up of professionals with technical knowledge
    </p>

    <div class="mt-5">
      <h3>
        Management Team
      </h3>
    </div>
    <div class="team-wrapper">
      <div
        v-for="(member, index) in executive_team"
        :key="index"
        class="team-main-wrapper"
      >
        <div class="team-box">
          <img class="team-image" :src="member.avatar" alt="" />
          <div class="team-info">
            <span class="team-name">{{ member.name }}</span>
            <span class="team-role">{{ member.role }}</span>
            <div class="team-social">
              <a :href="member.linkedIn"
                ><img src="@/assets/icons/linkedin-icon.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="mt-5">
      <h3>
        Leadership Team
      </h3>
    </div>
    <div class="team-wrapper">
      <div
        v-for="(member, index) in leadership_team"
        :key="index"
        class="team-main-wrapper"
      >
        <div class="team-box">
          <img class="team-image" :src="member.avatar" alt="" />
          <div class="team-info">
            <span class="team-name">{{ member.name }}</span>
            <span class="team-role">{{ member.role }}</span>
            <div class="team-social">
              <a :href="member.linkedIn"
                ><img src="@/assets/icons/linkedin-icon.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h3>
        Instructor Team
      </h3>
    </div>
    <div class="team-wrapper">
      <div
        v-for="(member, index) in instructor_team"
        :key="index"
        class="team-main-wrapper"
      >
        <div class="team-box">
          <img class="team-image" :src="member.avatar" alt="" />
          <div class="team-info">
            <span class="team-name">{{ member.name }}</span>
            <span class="team-role">{{ member.role }}</span>
            <div class="team-social">
              <a target="_blank" :href="member.linkedIn"
                ><img src="@/assets/icons/linkedin-icon.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      executive_team: [
        {
          name: "Adewuyi Omobolaji",
          avatar: require("@/assets/images/team/bolaji.jpeg"),
          role: "CEO",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/adewuyi-omobolaji-9a601114/",
          instagram: "",
        },
        {
          name: "Blessing Olayinka",
          avatar: require("@/assets/images/team/blessing.jpeg"),
          role: "COO",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/blessing-olayinka",
          instagram: "",
        },
        {
          name: "Prof. Olusola Ladokun",
          avatar: require("@/assets/images/team/olusola.jpeg"),
          role: "Advisory Board",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/olusola-ladokun-81678860",
          instagram: "",
        },
      ],
      leadership_team: [
        {
          name: "Gloria Owolabi",
          avatar: require("@/assets/images/team/gloria.jpeg"),
          role: "Administrative Officer",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/gloria-owolabi-2915bb1ab",
          instagram: "",
        },
        {
          name: "Michael Okosun",
          avatar: require("@/assets/images/team/michael.jpeg"),
          role: "HOD, Maths",
          twitter: "",
          linkedIn:
            "https://www.linkedin.com/in/okosun-michael-707105a5",
          instagram: "",
        },
      ],
      instructor_team: [
        {
          name: "Precious Folakemi",
          avatar: require("@/assets/images/team/precious.jpeg"),
          role: "Language Arts tutor",
          twitter: "",
          linkedIn:
            "https://www.linkedin.com/in/precious-olatunbosun-63bbb3231",
          instagram: "",
        },
        {
          name: "Joy Kanu",
          avatar: require("@/assets/images/team/joy.jpeg"),
          role: "Igbo tutor",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/joy-ngozi-10b340230",
          instagram: "",
        },
        {
          name: "Moses Parish",
          avatar: require("@/assets/images/team/parish.jpeg"),
          role: "French/Chinese tutor",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/moses-akachukwu-516301168",
          instagram: "",
        },
        {
          name: "Sodiq Ogunleye",
          avatar: require("@/assets/images/team/sodiq.jpeg"),
          role: "Maths tutor",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/ogunleye-sodiq-damilare-286aa61a6",
          instagram: "",
        },
        {
          name: "Peter Abimbola",
          avatar: require("@/assets/images/team/peter.jpeg"),
          role: "Chemistry/Maths",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/peterabimbola",
          instagram: "",
        },
        {
          name: "Eze Innocent",
          avatar: require("@/assets/images/team/eze.jpeg"),
          role: "Igbo tutor",
          twitter: "",
          linkedIn: "https://www.linkedin.com/in/okwuchukwu-eze-952545231",
          instagram: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.teams {
  padding: 60px 0;
  text-align: center;
  max-width: 1140px;
  margin: 0 auto;
}

.teams-label {
  border-radius: 4px;
  background: rgba(255, 76, 0, 0.15);
  color: #e07826;
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.teams > p {
  max-width: 705px;
  margin: auto;
}

span {
  display: block;
}

.team-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: center;
  margin: 55px auto 0 auto;
}

.team-box {
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.33);
  border: none;
  border-radius: 8px;
  background: white;
  padding: 20px 15px;
  text-align: center;
  width: 300px;
}

.team-box > img {
  border-radius: 8px;
  height: 250px;
  object-fit: cover;
  object-position: top;
}

.team-info {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.team-info > div {
  display: flex;
  align-items: center;
}

.team-image {
  margin-bottom: 20px;
}

.team-name {
  font-weight: 600;
  font-size: 20px;
}

.team-social {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .team-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }
}

@media only screen and (max-width: 840px) {
  .team-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
  }
}

@media only screen and (max-width: 1050px) {
  .team-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 2rem;
  }
}
</style>
