<template>
  <section>
    <div class="hero-grid">
      <div>
        <h1>
          Restoring Brilliance and Excellence
        </h1>
        <p>
          Take your education to the next level and gain critical insights about
          STEM and language subjects.
        </p>
        <div class="hero-action-grid">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSerDMreWBW7mFtevKtlamqGHJ51LdMx8iZUvLohdgtzU4pBJg/viewform"
            target="_blank"
          >
            <button class="button-primary cta-btn">Start Learning</button></a
          >
        </div>
      </div>
      <div>
        <img
          class="header-image"
          src="@/assets/images/home/hero-image.png"
          alt="hero-image"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Images from "../../assets/images/home";
import Icons from "../../assets/icons/home";
export default {
  data: () => {
    return {
      Images,
      Icons,
    };
  },
};
</script>
<style scoped>
.hero-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  align-items: center;
  padding-top: 65px;
}
.hero-grid h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  color: #220a00;
}
.hero-grid p {
  margin: 24px 0;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

.hero-grid img {
  width: 300px;
}
.hero-grid .hero-action-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 5px;
}
.hero-grid .hero-action-grid .cta-btn {
  width: 214px;
  height: 56px;
}
.hero-grid .hero-action-grid .vertical-center {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
}
.hero-grid .hero-action-grid .vertical-center img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.hero-grid .hero-action-grid .vertical-center .underlined-link {
  text-decoration: underline;
}
.hero-grid .header-image {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .hero-grid {
    gap: 40px;
  }
  .hero-grid h1 {
    font-weight: 600;
    font-size: 40px;
  }
  .hero-grid p {
    font-size: 16px;
    width: 80%;
  }
  .hero-grid .hero-action-grid {
    column-gap: 10px;
    width: 100%;
  }
  .hero-grid .hero-action-grid .cta-btn {
    width: 100%;
    height: 50px;
  }
  .hero-grid .hero-action-grid .vertical-center {
    gap: 0;
  }
  .hero-grid .hero-action-grid .vertical-center img {
    width: 70%;
  }
  .hero-grid .hero-action-grid .vertical-center .underlined-link {
    text-decoration: underline;
    font-size: 12px;
  }
}
</style>
