<template>
  <section>
    <div id="subjects" class="course-div-heading">
      <h4>Class subjects</h4>
      <p>Find your interests by browsing our online categories</p>
    </div>
    <div class="course-div">
      <div class="card-container" v-for="i in subjects">
        <div class="shadow-1">
          <p>{{i.subject}}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Icons from "../../assets/icons/home";
import homeImages from "../../assets/images/home";
export default {
  data: () => {
    return {
      Icons,
      homeImages,
      subjects: [
        {
          subject: "Maths",
        },
        {
          subject: "English/Language/Art",
        },
        {
          subject: "Physics",
        },
        {
          subject: "Science",
        },
        {
          subject: "Biology",
        },
        {
          subject: "Chemistry",
        },
        {
          subject: "Social",
        },
        {
          subject: "Chinese",
        },
        {
          subject: "German",
        },
        {
          subject: "Spanish",
        },
        {
          subject: "French",
        },
        {
          subject: "Igbo",
        },
        {
          subject: "Yoruba",
        },
        {
          subject: "Coding",
        },
        {
          subject: "Animation",
        },
      ],
    };
  },
};
</script>
<style scoped>
.course-div {
  margin-top: 60px;
  text-align: center;
  width: 100%;
  display: grid;
  gap: 20px;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.course-div-heading {
  margin-top: 100px;
  display: grid;
  place-items: center;
}

.course-div-heading h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px !important;
  line-height: 45px;
  color: #220a00;
}

.course-div-heading p {
  font-size: 18px !important;
  font-weight: 400;
  margin: 20px 0;
}

.card-container {
  width: 250px;
  height: 80px;
}

.card-container .shadow-1 {
  border-radius: 11px;
  /* border: 1px solid #fd7526; */
  height: 100%;
  width: 100%;
}

.card-container p {
  height: 100%;
  display: grid;
  place-items: center;
  margin: 0 auto;
  font-size: 18px;
}

.btn-outlined {
  border: 1px solid var(--primary);
  color: var(--primary);
  height: 45px;
  margin: 66px auto;
  border-radius: 5px;
  padding: 12px 35px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
}
</style>
