<template>
  <section class="why">
    <h3>Why MSciClinic Works</h3>
    <div class="why-wrapper">
      <div v-for="(why, index) in whys" :key="index" class="why-main-wrapper">
        <div class="why-box">
          <img :src="why.icon" alt="" />
          <span class="why-heading">{{ why.heading }}</span>
          <span class="why-sub">{{ why.sub }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      whys: [
        {
          heading: "Personalized Learning",
          sub: "Learners practice at their own pace, first filling in gaps in their understanding and then accelerating their learning.",
          icon: require("@/assets/icons/personalized-learning.svg"),
        },
        {
          heading: "Trusted Content",
          sub: "Created by experts, MSciClinic library of trusted practice and lessons covers STEM and language subjects.",
          icon: require("@/assets/icons/trusted-content.svg"),
        },
        {
          heading: "Empowering Learners",
          sub: "With MSciClinic, learners have direct access to teachers at recognized institutions.",
          icon: require("@/assets/icons/empowering-learners.svg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.why {
  padding: 60px 0;
  text-align: center;
  max-width: 1050px;
  margin: 0 auto;
}

h3 {
  font-size: 28px;
  font-weight: bold;
}

img {
  width: 65px;
}

span {
  display: block;
}

.divider {
  background: #ff4c00;
  height: 8px;
  width: 125px;
  border-radius: 14px;
  display: inline-block;
}

.why-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin: 55px auto 0 auto;
}

.why-heading {
  font-weight: bold;
  margin: 15px 0 30px 0;
  font-size: 24px;
  font-weight: bold;
}

.why-sub {
  font-size: 18px;

  line-height: 1.7;
}

@media only screen and (max-width: 480px) {
  .why-wrapper{ 
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  }

  .divider {
    display: none;
  }
}
</style>