exports.frequently_asked_questions = [
  {
    question: `How can I enroll my child?`,
    answer: `You can enroll your child by clicking on the registration link on our website or our facebook business page.`,
  },
  {
    question: `Which package is available?`,
    answer: `There’s the personalised (one-one-one), and the group package. Kindly check our website for the list of the available packages.`,
  },
  {
    question: `What do I need to put in place for seamless class encounter?`,
    answer: `Good internet access, a laptop/mobile device or likely device for connecting, presence in the Google classroom(s) to access learning materials, exercise and assignment.`,
  },
  {
    question: `Can my child be taught in line with the school’s scheme of work?`,
    answer: `Definitely! All we need is the subject’s scheme of work from the school, as well the materials being used.`,
  },
  {
    question: `I only want my child to be helped with his school homework, not to take lessons. Can I enroll?`,
    answer: `Yes, you can! If that’s the engagement you want, our tutors are always ready and up to that task. You can definitely enroll.`,
  },
  {
    question: `Which curriculum does MSciClinic offer?`,
    answer: `BRITISH, IGCSE, IELTS, Nigerian, Nigerian-British curriculum. We are generally up to date and flexible with your specified school curriculum, regardless your location.`,
  },
  {
    question: `What is the time duration for each class?`,
    answer: `1 hour. Extra time attracts extra charges.`,
  },
  {
    question: `My child is too young to go on a 1 hour class. Is there provision for lesser timing?`,
    answer: `For young kids, 30 minutes session is available, if you do not want to go on a 1 hour class.`,
  },
  {
    question: `Can I request for a class extension beyond the specified time?`,
    answer: `Yes, but extra charges apply.`,
  },
  {
    question: `Will there be assessments for the students and in what way will they be assessed?`,
    answer: `Our assessments come in varied forms: in class, occasionally before the end of a session, and every week, via Google classroom.`,
  },
  {
    question: `Will learning be group learning or individualistic?`,
    answer: `This depends on your choice. We have provision for one-on-one learning, as well as group learning.`,
  },
  {
    question: `What do I stand to gain if I enrol my ward?`,
    answer: `
    ✓ It's cost efficient.
    ✓ Learning at your convenience and time specification.
    ✓ You will have the opportunity of experiencing the best teaching techniques.
    ✓ Learning made easy and simplified in basic form for maximum understanding.
    ✓ 100% guarantee of success in exams.
    ✓ Access to teaching materials and learning contents from anywhere, anytime, on any device.
    ✓ Increase user engagement.
    ✓ Get insightful reports about your learners.
    ✓ Effective periodical assessment and examination.`,
  },
  {
    question: `Do you offer summer classes?`,
    answer: `Yes, we do.`,
  },
  {
    question: `Apart from class room subjects, what skill set can the platform provide for my ward?`,
    answer: ` 
    ✓ Coding
    ✓ Animation
    ✓ Graphic designs
    ✓ Web designs`,
  },
  {
    question: `Do you also train adults in language arts for non-English speaking countries?`,
    answer: `Yes, we do.`,
  },
  {
    question: `Does MSciClinic provide reminders and other kinds of support to help kids who have trouble getting or staying organized?`,
    answer: `Yes. The tutors remind students of their classes by sending the class links to them at least 4 hours before class time.  They also ensure that the students are notified once the class link is on.`,
  },
  {
    question: `What happens if my child misses a class?`,
    answer: `If a student misses a class, there may be an extension in the duration of the next class to make up for the missed class.`,
  },
  {
    question: `How do tutors provide feedback? How often do they do this?`,
    answer: `Tutors give reports of their engagement with students to the management biweekly. The parents can therefore get feedback from the management.`,
  },
  {
    question: `How do I track my child’s progress?`,
    answer: `There's room for progress reports of new students from their tutors with their parents after the first month. Parents can get this for checks and balances and get feedback as required from the management.`,
  },
  {
    question: `Have your tutors received training on how to teach students who learn and think differently in an online setting?`,
    answer: `Yes. Our tutors are highly qualified and experienced in online course delivery. They are capable of bringing the best out of every child regardless of how they learn or think.`,
  },
  {
    question: `Do students need to be technically-strong in order to avail your courses?`,
    answer: `It does not require the students to be tech-savvy right from the beginning. In the process of attending classes and doing assignments, a student automatically becomes tech-savvy. He or she even learns technical shortcuts soon.`,
  },
  {
    question: `Do you offer other languages aside from English language?`,
    answer: `Yes, we offer French, Spanish, German, Chinese, Portuguese, Igbo, Yoruba, Hausa e.t.c`,
  },
];
