<template>
  <div style="position: relative">
    <!-- <notificationBanner/> -->
    <Navigation />
    <router-view />
    <Footer />

    <!-- back to top icon -->
    <a href="#">
      <!-- <div class="circle-glass goup">
        <img alt="back to top" src="@/assets/icons/goup.svg" />
      </div> -->
    </a>
  </div>
</template>


<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      state: "show",
      showAppMenu: false,
      appmenuclass: "hidden",
    };
  },
  methods: {
    toggleMenu() {
      if (this.state != "show") {
        this.state = "show";
      } else {
        this.state = "none";
      }
    },
    toggleAppMenu() {
      if (this.appmenuclass != "display") {
        // this.showAppMenu = true;
        this.appmenuclass = "display";
      } else {
        // this.showAppMenu = false;
        this.appmenuclass = "hidden";
      }
    },
  },
};
</script>

<style scoped>
.goup {
  position: absolute;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  right: 30px;
  bottom: 130px;
  position: fixed;
  padding: 15px;
  background: var(--primary);
}

@media (max-width: 768px) {
  .goup {
    position: absolute;
    color: white;
    width: 40px;
    height: 40px;
    bottom: 120px;
    border-radius: 50%;
    right: 25px;
    display: grid;
    place-items: center;
    position: fixed;
    padding: 15px;
  }
}
</style>