<template>
  <div class="banner2">
    <div class="banner2-text">
      <h3>Professional Learning <br/> Materials for STEM Courses</h3>
      <p class="txt-b2">
        Learn and understand STEM subjects from tested and trusted instructors & trainers.
      </p>
      <button class="explore">Explore our resources</button>
    </div>
    <div class="banner-img">
      <img :src="`${homeImages.indexWelcome2}`" alt="" />
    </div>
  </div>
</template>
<script>
import homeImages from "../../assets/images/home"
export default {
  data:()=>{
   return{
      homeImages,
   }
  },
}
</script>
<style scoped>
  .banner2{
    background: #fff;
    display: grid;
    grid-template-areas: "text image";
    grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    gap:100px;
    padding:50px 150px;
    place-items: center;
    font-family: Mulish;
    font-style: normal;
  }
  .banner2 .banner2-text h3{
    
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 26px;
  }
  .banner2 .banner2-text p{
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 26px;
  }
  .banner2 .banner2-text .explore{
    width:214px;
    background: var(--primary);
    border: none;
    border-radius:5px;
    font-size: 16px ;
    height: 50px;
    cursor:pointer;
    color: #fff;
  }
  .banner2 .banner-img{
    width: 430px;
  }
  @media screen and (max-width:768px){
    .banner2{
      display: none;
    }
    .banner2 .banner-img{
      width:100%;
    }
  }
</style>