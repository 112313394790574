<template>
  <section>
    <team />
  </section>

</template>

<script>
import team from "./team/team.vue";
export default {
  components:{
    team,
  },
}
</script>

<style>
</style>