<template>
  <section>
    <div style="margin-top: 5em"></div>
    <h3 class="title-h1">What we offer?</h3>
      <div class="offer">
        <div class="shadow-1">
          <img :src="`${homeImages.accesImg}`" alt="" />
          <div class="card-item">
            <h5>Unlimited Library Access</h5>
          </div>
        </div>
        <div class="shadow-1">
          <img :src="`${homeImages.cert}`" alt="" />
          <div class="card-item">
            <h5>Certificate of Completion</h5>
          </div>
        </div>
        <div class="shadow-1">
          <img :src="`${homeImages.code}`" alt="" />
          <div class="card-item">
            <h5>How and When You want it</h5>
          </div>
        </div>
        <div class="card shadow-1">
          <img :src="`${homeImages.interact}`" alt="" />
          <div class="card-item">
            <h5>Interactive Learning</h5>
          </div>
        </div>
        <div class="shadow-1">
          <img :src="`${homeImages.personalized}`" alt="" />
          <div class="card-item">
            <h5>Personalised for You</h5>
          </div>
        </div>
        <div class="shadow-1">
          <img :src="`${homeImages.job}`" alt="" />
          <div class="card-item">
            <h5>Continued learning</h5>
          </div>
        </div>
      </div>
  </section>
</template>
<script>
import homeImages from "../../assets/images/home";
export default {
  data:() => {
    return{
      homeImages,
    }
  }
}
</script>
<style scoped>
  .title-h1 {
    margin-top: 100px;
    width: 300px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0 auto;
    line-height: 2em;
  }

 
  .offer{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    gap:45px;
    margin:60px auto;
    padding: 10px;
  }
  .offer .shadow-1 img{
    width: 50px;
    height: 50px;
    margin:20px 0 25px 20px;
  }
  .offer .shadow-1 .card-item{
    margin:0 0 25px 20px;
  }
  .offer .shadow-1 .card-item h5{
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }
  .offer .shadow-1 .card-item p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 16px;
  }
</style>