<template>
  <div class="navbar">
    <div class="container-center">
      <a href="/">
      <img class="logo" src="@/assets/images/logo/logo.png"/>
      </a>
      <input id="menu-btn" class="menu-btn" type="checkbox" />
      <label class="menu-icon" for="menu-btn">
        <span class="navicon"></span
      ></label>
      <ul class="menu">
        <li class="menu-item">
          <a href="/#pricing">Pricing</a>
        </li>
        <li class="menu-item">
          <a href="/#subjects">Subjects</a>
        </li>
        <li class="menu-item">
          <a href="team">Team</a>
        </li>
        <li class="menu-item">
          <a href="/#about">About</a>
        </li>
        <li class="menu-item">
          <a class="button-primary" href="https://docs.google.com/forms/d/e/1FAIpQLSerDMreWBW7mFtevKtlamqGHJ51LdMx8iZUvLohdgtzU4pBJg/viewform" target="_blank">
            Enroll
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      host: "https://msclinic.org",
    };
  },
};
</script>

<style scoped>
.primary_color {
  color: var(--primary-color) !important;
}

.menu router-link {
  font-size: 20px;
  color: black;
}

.menu-item {
  padding: 0px 0px;
  width: 50%;
  text-align: center;
}

.navbar {
  width: 100%;
  height: 90px;
  background: #FFFFFF;
  position: sticky !important;
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  color: black;
}

.navbar .logo {
  width: 150.79px;
  object-fit: cover;
}

.navbar ul {
  margin: 0;
  padding: 0;
  height: 100%;
  list-style: none;
  overflow: hidden;
  z-index: 999;
  transition: all .5s;
}

.navbar li a {
  font-size: 14px;
  padding: 10px 20px;
  text-decoration: none;
  color: black ;
}

.navbar .logo {
  margin-top: 20px;
}
/* menu */

.navbar .menu {
  clear: both;
  max-height: 0;
}

.navbar .menu {
  width: 50%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  row-gap: 30px;
}

@media (max-width: 1068px) {
  .navbar .menu {
    background: white !important;
    position: sticky !important;
    height: 90vh;
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 0px;
  }
}


/* menu icon */

.navbar .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  margin-top: 40px;
  position: relative;
  user-select: none;
}

.navbar .menu-icon .navicon {
  background: black;
  display: block;
  height: 2px;
  position: relative;
  width: 18px;
}

.navbar .menu-icon .navicon:before,
.navbar .menu-icon .navicon:after {
  background: black;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.navbar .menu-icon .navicon:before {
  top: 5px;
}

.navbar .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.navbar .menu-btn {
  display: none;
}

.navbar .menu-btn:checked ~ .menu {
  max-height: 100vh;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media (min-width: 1064px) {
  .navbar {
    padding: 0 50px;
    position: sticky;
  }

  .menu-item {
    padding: 0px 0px;
    width: 100%;
  }

  .navbar li {
    float: left;
    font-size: 14px;
  }

  .navbar li a {
    padding: 20px 20px;
  }

  .navbar .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .navbar .menu-icon {
    display: none;
  }

  .navbar li a {
    display: grid;
    place-items: center;
    padding: 15px 20px;
    text-decoration: none;
  }
}

.container-center {
  width: 85% !important;
  height: 100%;
  margin: 0 auto;
}

@media (max-width: 1068px) {
  .container-center {
    width: 100% !important;
  }
  .logo {
    padding-left: 20px;
  }
  .navbar .menu-icon {
    padding-right: 20px;
  }
}
</style>
