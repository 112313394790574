<template>
  <section class="testimonials">
    <div class="center">
      <h3>What People Say About us</h3>
    </div>
    <div class="testimony mt-4">
      <div class="testimony-wrapper" v-for="i in testimony">
        <p class="testifier-text">{{ i.text }}</p>
        <p class="testifier-name">{{ i.name }}</p>
        <p>{{ i.role }}</p>
      </div>
    </div>
  </section>
</template>

<script>

let testimony = [
  {
    text: `When I tell you God bless you and your team, I ain't playing! Science improved. Maths improved. Lets take it home! You promised an A and you are almost there I am sceaming blessings from the heavens to you and your team!`,
    name: "Mrs. L.O",
    role:  `NY, USA`,
  },
  {
    text: `Thanks Michael for the job well done. We appreciate all your efforts. Thanks to you too Bolaji. You're doing a great job with your team`,
    name: `Mrs. NG, Alberta`,
    role:  `Canada`,
  },
  {
    text: `The first week of learning under your platform has been interesting and I must say my boy loves it`,
    name: `Dr. Gbenusola A.`,
    role: `University of Lagos`,
  },
]

export default {
  data() {
    return {
      testimony
    };
  },
};
</script>

<style scoped>
h3 {
  font-size: 28px;
  font-weight: bold;
}

.testimony {
  display: flex;
  row-gap: 30px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.testimony-wrapper {
  flex-basis: 350px;
  background: white;
  box-shadow: 0px 2px 30px 0px rgba(196, 196, 196, 0.35);
  padding: 35px;
}

.testifier-text {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.testifier-name {
  color: #ff4c00;
  font-weight: 600;
  line-height: 1.5;
}

.testifier-role {
  font-weight: 600;
  font-size: 14;
}
</style>